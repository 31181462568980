// Get Available allOwnedWorkspaces
export const allOwnedWorkspaces = /* GraphQL */ `
  query (
    $order: [WorkspaceInputModelSortInput!]
    $where: WorkspaceInputModelFilterInput
  ) {
    allOwnedWorkspaces(order: $order, where: $where) {
      name
      id
      description
      configuration
      permissions {
        accessingWorkGroupId
        permissionCode
      }
      makeFav
      enabled
    }
  }
`;

// Get Available allSharedWorkspaces
export const allSharedWorkspaces = /* GraphQL */ `
  query (
    $order: [WorkspaceInputModelSortInput!]
    $where: WorkspaceInputModelFilterInput
  ) {
    allSharedWorkspaces(order: $order, where: $where) {
      name
      id
      description
      configuration
      permissions {
        accessingWorkGroupId
        permissionCode
      }
      makeFav
      enabled
    }
  }
`;

// Get Available Workspaces By Id
export const workspaceById = /* GraphQL */ `
  query (
    $workSpaceId: Int!
    $order: [WorkspaceInputModelSortInput!]
    $where: WorkspaceInputModelFilterInput
  ) {
    workspaceById(workSpaceId: $workSpaceId, order: $order, where: $where) {
      name
      id
      description
      configuration
      permissions {
        accessingWorkGroupId
        permissionCode
      }
      makeFav
      enabled
    }
  }
`;
